import React, { createContext, useReducer } from "react"

const Reducer = (state, action) => {
  switch (action.type) {
    case "AUTH0":
      return {
        ...state,
        auth0: action.payload,
      }
    case "USER_DATA":
      return {
        ...state,
        user: action.payload,
      }
    case "TOGGLE_CART":
      return {
        ...state,
        windowOpen: action.payload,
      }
    case "SET_CART":
      return {
        ...state,
        cart: action.payload,
      }
    case "ADD_TO_CART":
      return {
        ...state,
        cart: state.cart.concat(action.payload),
        windowOpen: action.payload,
      }
    case "SET_METADATA":
      return {
        ...state,
        metadata: action.payload,
      }
    default:
      return state
  }
}

const initialState = {
  auth0: null,
  user: null,
  metadata: null,
  cart: [],
  windowOpen: false,
  error: null,
}

const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState)

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  )
}

export default Provider

export const Context = createContext(initialState)
